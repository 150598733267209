import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Divider
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


const ProgramacaoModal = ({ open, handleClose }) => {
  // Estado para armazenar a programação e DJs
  const [programacao, setProgramacao] = useState([]);
  const [djs, setDjs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Verifica se o dispositivo é mobile usando breakpoints do tema
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch da API quando o modal abre
  useEffect(() => {
    if (open) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const [programacaoResponse, djsResponse] = await Promise.all([
            fetch('https://webradiosoundmix.com.br/api/wp-json/wp/v2/programacao?per_page=100'), // Garante que todos os dados de programação sejam trazidos
            fetch('https://webradiosoundmix.com.br/api/wp-json/wp/v2/djs?per_page=100') // Garante que todos os DJs sejam trazidos
          ]);

          const programacaoData = await programacaoResponse.json();
          const djsData = await djsResponse.json();

          setProgramacao(programacaoData);
          setDjs(djsData);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [open]);

  // Função para normalizar o nome do dia (remover acentos e converter para minúsculas)
  const normalizeDayName = (day) => {
    return day.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  // Mapeia DJs por nome para fácil acesso
  const getDjsMap = () => {
    const djsMap = new Map();
    djs.forEach(dj => {
      djsMap.set(dj.meta['nome-do-dj'], dj);
    });
    return djsMap;
  };

  const djsMap = getDjsMap();

  const daysOfWeek = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];

  // Obtém o dia atual da semana
  const getCurrentDay = () => {
    const now = new Date();
    const dayIndex = now.getDay();
    return daysOfWeek[dayIndex === 0 ? 6 : dayIndex - 1]; // Ajusta índice para o formato da semana
  };

  // Obtém o horário atual no formato HH:MM
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();

  // Organiza programas por dia da semana e ordena por hora de início
  const programsByDay = daysOfWeek.reduce((acc, day) => {
    const normalizedDay = normalizeDayName(day);
    acc[day] = programacao
      .filter(program => normalizeDayName(program.meta.dia) === normalizedDay)
      .sort((a, b) => a.meta['hora-inicio'].localeCompare(b.meta['hora-inicio']));
    return acc;
  }, {});

  // Verifica se um programa está no ar com base no horário
  const isProgramOnAir = (program) => {
    const startTime = program.meta['hora-inicio'];
    const endTime = program.meta['hora-fim'];
    return currentTime >= startTime && currentTime <= endTime;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.8)'
      }}
      closeAfterTransition
    >
<Fade in={open}>
  <Box
    sx={{
      width: isMobile ? '100%' : '80%',
      maxHeight: '80vh',
      overflowY: 'auto',
      bgcolor: '#333',
      color: '#fff',
      borderRadius: 2,
      p: 2,
      position: 'relative',
      zIndex: 1300
    }}
  >
    {/* Botão de fechar o modal */}
    <IconButton
      onClick={handleClose}
      sx={{
        position: 'absolute',
        top: 10,
        right: 20,
        color: 'white',
        zIndex: 1400,
        '&:hover': {
          backgroundColor: '#FD0103',
          color:'white'
        }
      }}
    >
      <CloseIcon />
    </IconButton>

    {/* Exibe mensagem de carregamento */}
    {loading ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          bgcolor: '#333',
          color: '#fff',
          borderRadius: 2,
        }}
      >
        <CircularProgress color="inherit" />
        <Typography variant="body2" sx={{ ml: 2 }}>Carregando dados, aguarde...</Typography>
      </Box>
    ) : (
      <>
        {/* Cabeçalhos */}
        {isMobile ? (
          <Typography sx={{ pt: 5 }} variant="h6" component="h2" gutterBottom>
            GRADE DA PROGRAMAÇÃO AO VIVO
          </Typography>
        ) : (
          <Typography variant="h5" component="h3" gutterBottom>
            GRADE DA PROGRAMAÇÃO AO VIVO
          </Typography>
        )}

        {/* Conteúdo para mobile */}
        {isMobile ? (
          <Box sx={{ p: 1 }}>
            {daysOfWeek.map(day => (
              <Box key={day} sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ pl: 2, backgroundColor: day === currentDay ? '#34752e' : '#2E8DFA', color: '#fff' }}>
                  {day}
                </Typography>
                {programsByDay[day].map((program, index) => {
                  const dj = djsMap.get(program.meta.dj);
                  const onAir = day === currentDay && isProgramOnAir(program);
                  return (
                    <Box key={index} sx={{ mb: 1, bgcolor: onAir ? '#555' : '#333', p: 1, borderRadius: 1 }}>
                      {/* Nome do Programa */}
                      <Typography sx={{ fontWeight: '700', padding: '2px 0' }} variant="body2">
                        {program.meta['nome-do-programa']}
                      </Typography>

                      {/* Horário do Programa */}
                      <Typography sx={{ fontWeight: '700', padding: '2px 0' }} variant="caption">
                        {program.meta['hora-inicio']} - {program.meta['hora-fim']}
                      </Typography>

                      {/* Informações do DJ */}
                      {dj && (
                        <>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <img
                              src={dj.meta['foto-do-dj']}
                              alt={dj.meta['nome-do-dj']}
                              style={{
                                width: 40, // Reduzindo o tamanho da imagem
                                borderRadius: '50%',
                                marginRight: 8
                              }}
                            />
                            <Typography
                              sx={{
                                fontFamily: '"Orbitron", sans-serif',
                                letterSpacing: '0.0em',
                                textTransform: 'uppercase',
                                fontWeight: '700',
                                fontSize: '12px' // Reduzindo o tamanho do texto
                              }}
                              variant="caption"
                            >
                              {dj.meta['nome-do-dj']}
                            </Typography>
                          </Box>
                          <Divider sx={{ my: 1, bgcolor: '#fff' }} />
                        </>
                      )}

                      {/* Indicação de programa no ar */}
                      {onAir && (
                        <Box
                          sx={{
                            mt: 1,
                            bgcolor: '#4CAF50',
                            color: '#fff',
                            borderRadius: 1,
                            padding: '2px 5px',
                            fontSize: '0.75rem',
                            textAlign: 'center'
                          }}
                        >
                          NO AR
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        ) : (
          // Conteúdo para desktop
<TableContainer component={Paper} sx={{ bgcolor: '#444', color: '#fff', padding: 0 }}>
  <Table
    stickyHeader
    sx={{
      tableLayout: 'fixed',  // Força as células a terem o mesmo tamanho
      width: '100%',
      borderSpacing: '2px 2px', // Mantém espaçamento menor
    }}
  >
    <TableHead>
      <TableRow>
        {daysOfWeek.map((day) => (
          <TableCell
            key={day}
            align="center"
            sx={{
              bgcolor: day === currentDay ? '#fff' : '#222',
              color: day === currentDay ? '#FD0103' : 'yellow',
              fontWeight: day === currentDay ? '700' : 'normal',
              padding: '6px',
              fontSize: day === currentDay ? '0.975rem' : '0.875rem',
              width: '14.28%'  // Ajusta para que todas as colunas tenham tamanho igual (7 dias)
            }}
          >
            {day}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        {daysOfWeek.map((day) => (
          <TableCell key={day} align="left" sx={{ verticalAlign: 'top', padding: '6px', width: '14.28%' }}>
            {programsByDay[day].map((program, index) => {
              const dj = djsMap.get(program.meta.dj);
              const onAir = day === currentDay && isProgramOnAir(program);
              return (
                <Box key={index} sx={{ mb: 1, p: 1, bgcolor: onAir ? '#34752e' : '#333', color: '#fff', borderRadius: 1 }}>
                  {onAir && (
                    <Box
                      sx={{
                        mt: 1,
                        bgcolor: '#4CAF50',
                        color: '#fff',
                        borderRadius: 1,
                        padding: '2px 5px',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                      }}
                    >
                      NO AR
                    </Box>
                  )}
                  <Typography sx={{ fontWeight: '700', fontSize: '0.875rem', padding: '2px 0' }} variant="body2">
                    {program.meta['nome-do-programa']}
                  </Typography>
                  <Typography sx={{ fontWeight: '700', fontSize: '0.75rem', padding: '2px 0' }} variant="caption">
                    {program.meta['hora-inicio']} - {program.meta['hora-fim']}
                  </Typography>
                  {dj && (
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <img
                        src={dj.meta['foto-do-dj']}
                        alt={dj.meta['nome-do-dj']}
                        style={{ width: 40, height:40, borderRadius: '50%', marginRight: 8 }}
                      />
                      <Typography
                        sx={{
                          fontFamily: '"Orbitron", sans-serif',
                          letterSpacing: '0.0em',
                          textTransform: 'uppercase',
                          fontWeight: '700',
                          fontSize: '10px',
                          color:'#C4FFF8'
                        }}
                        variant="caption"
                      >
                        {dj.meta['nome-do-dj']}
                      </Typography>
                    </Box>
                  )}

                </Box>
              );
            })}
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

        )}
      </>
    )}
  </Box>
</Fade>


    </Modal>
  );
};

export default ProgramacaoModal;
